import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { StaticImage, GatsbyImage, getImag } from "gatsby-plugin-image"
import Layout from "../components/layout"

const IndexPage = () => {
  const { allStrapiPost } = useStaticQuery(graphql`
    query {
      allStrapiPost {
        nodes {
          id
          slug
          Titolo
          Descrizione
          Immagine {
            name
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <main>
        <div className="container mt-12 grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
          {allStrapiPost.nodes.map((post) => (
            <Link
            to={`${post.slug}`}
            className="overflow-hidden rounded-lg bg-white shadow-sm transition-shadow hover:shadow-md"
            >
              <StaticImage
              src="https://strapi.mwg.it/uploads/random_71670cefbd.jpg"
              alt={"testo"}
              />
              <div className="px-4 py-4">
              <h3 className="font-bold text-neutral-700">{post.Titolo}</h3>
              <p className="line-clamp-2 mt-2 text-neutral-500">
                  {post.Descrizione}
              </p>
              </div>
            </Link>
          ))}
        </div>
      </main>
    </Layout>
  )
}

export default IndexPage
